
<template>
  <div>
    
  </div>
</template>

<script>

export default {
  components: {
    FormAnswer,
  },
  props: {
    short_url_id: {
      type: String,
      default: null,
    },
  },
  data: () => ({}),
  methods: {
    getAnswerData() {
      if (this.$store.state.auth.isUserLoggedIn()) {
        this.$vs.loading()
        this.service.canAnswer(this.short_url_id, this.preview).then(
          response => {
            this.questionnaire = response

            console.log('response...', response)
            this.$vs.loading.close()
            this.multipleAttempts = this.questionnaire.multiple_attempts

            this.durationShortage = _.get(response, 'duration_shortage', false)

            if (!this.validateEnvRestriction(this.questionnaire.env_restriction)) {
              this.startError = this.envRestrictionMessage(this.questionnaire.env_restriction)
            }

            if (this.questionnaire.starErrorMessage) {
              this.startError = this.questionnaire.starErrorMessage
            }

            if (this.questionnaire.startErrorCode) {
              this.startErrorCode = this.questionnaire.startErrorCode
            }

            const fullScreenCompability = this.$utils.browser.fullScreenCompability()
            if (this.questionnaire && this.questionnaire.force_fullscreen && !fullScreenCompability) {
              this.startError = 'Não é possível realizar esta avaliação neste browser. Favor usar a versão mais recente do Firefox, Chrome ou Opera para Desktop e Notebook.'
            }

            if (response && 'organization' in response) {
              this.organization = response.organization
              this.organizationService.logo(response.organization.id).then(
                logo => {
                  this.organizationLogo = logo.file
                },
                error => {
                  console.error('Erro ao trazer a logo da organização.', error)
                }
              )
            }

            this.questionnaireDescription()


            if (this.isReturning) {
              /**
              * 60000 is the constant to convert minutes to milliseconds
              * since 60 * min = seconds
              * seconds * 1000 = milliseconds
              * thus 60000 * min = milliseconds
              */
              this.questionnaire.answer_unfinished.timeout = 
              (response.duration * 60000) - 
              (response.since_creation * 1000)
            }

            this.showPassword = this.questionnaire.showPassword
            if (this.questionnaire.answer_unfinished !== undefined) {
              this.continue = true
            }
            this.showStart = true

            if (this.isProctored && !this.preview) {
              this.rebuildServicesForProctoring()
            }
          },
          responseError => {
            const data = JSON.parse(responseError.data)
            if (data && data.errors) {
              const errors = JSON.parse(data.errors)
              this.startError = errors[0]
            }
            this.$vs.loading.close()
          }
        )
      } else {
        this.notifyWarning(this.$vs, 'Você deve entrar na plataforma para fazer o questionário.', 8000)
        this.$router.push('/login')
      }
    },
  },
  beforeMount() {
    this.service = ContentQuestionnaireService.build(this.$vs)
    this.getAnswerData()
  },
}
</script>

<style>
</style>
